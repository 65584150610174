//Note: do not check in over this. When we run locally, we will be able to identify local testing using this.
export const buildInfo = {
  name: 'com.cts.ParaScope',
  ionicAppId: '6b9ec9dc',
  ionicAppChannel: 'configurable',
  publicVersion: '7.0.0',
  baselineBuildId: 8610226,
  date: '2024-10-10 13:09:24.460Z',
  internalVersion: '24.284.789',
};
