<ion-card [ngClass]="nonServicePeriod.hasBeenStarted ? 'nsp-card-started' : 'nsp-card'" *ngIf="!nonServicePeriod.hasBeenCompleted">
  <ion-grid>
    <ion-row>
      <ion-col size="12" sizeSm="9">
        <ion-card-title>
          <span [ngClass]="{ 'late-stop' : nonServicePeriod.inThePast}">
            {{ nonServicePeriod.scheduledStart | amDateFormat:'HH:mm' }}
            <span *ngIf="nonServicePeriod.scheduledStart != nonServicePeriod.scheduledEnd">
              {{ 'LABEL.to' | translate }} {{ nonServicePeriod.scheduledEnd | amDateFormat:'HH:mm' }}
            </span>
          </span>
          <span>{{ 'LABEL.scheduled' | translate }} {{ typeString }}</span>
        </ion-card-title>
        <ion-card-subtitle>
          {{ typeString }} {{ nonServicePeriod.timeToStop }}
        </ion-card-subtitle>
      </ion-col>
      <ion-col size="12" sizeSm="3">
        <ion-card-content>
          <ion-button
            type="button"
            expand="block"
            color="primary"
            (click)="startClicked()"
            *ngIf="nonServicePeriod.isEnabled && !data.currentRouteIsPreview && !nonServicePeriod.hasBeenStarted"
          >
            <ion-icon name='alarm' slot="start"></ion-icon>
            <span>{{ 'ACTION.start' | translate }}</span>
          </ion-button>
          <ion-button
            type="button"
            expand="block"
            color="primary"
            (click)="finishClicked()"
            *ngIf="nonServicePeriod.isEnabled && !data.currentRouteIsPreview && nonServicePeriod.hasBeenStarted"
          >
            <ion-icon name='checkmark-circle-outline' slot="start"></ion-icon>
            <span>{{ 'ACTION.finish' | translate }}</span>
          </ion-button>
        </ion-card-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
