import { Component, Input, OnInit, inject } from '@angular/core';
import { NonServicePeriod, NonServicePeriodActionEnum, NonServicePeriodTypeEnum } from '../../models/non-service-period';

import { AlertService } from '../../services/alert.service';
import { AppData } from 'src/app/data';
import { DeviceService } from '../../services/device.service';
import { DispatcherService } from '../../services/dispatcher.service';
import { InspectionItem } from '../../models/inspection';
import { InspectionPage } from '../../pages/inspection/inspection.page';
import { ModalController } from '@ionic/angular';
import { RouteProviderService } from '../../services/route-provider.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '../../services/translate.service';

@Component({
  selector: 'app-non-service-period',
  templateUrl: './non-service-period.component.html',
  styleUrls: ['./non-service-period.component.scss'],
})
export class NonServicePeriodComponent implements OnInit {

  public readonly data = inject(AppData);

  @Input() nonServicePeriod: NonServicePeriod;
  typeString = '';

  constructor(public alertService: AlertService,
    public router: Router,
    private routeProvider: RouteProviderService,
    public deviceService: DeviceService,
    private modalController: ModalController,
    public dispatcherService: DispatcherService,
    private translateService: TranslateService,
    private title: Title,
  ) {
  }

  ngOnInit() {
    switch (this.nonServicePeriod.nonServicePeriodType) {
      case NonServicePeriodTypeEnum.PreTrip:
        this.typeString = this.translateService.translate('LABEL.preTrip');
        break;
      case NonServicePeriodTypeEnum.Break:
        this.typeString = this.translateService.translate('LABEL.break');
        break;
      case NonServicePeriodTypeEnum.PostTrip:
        this.typeString = this.translateService.translate('LABEL.postTrip');
        break;
    }
  }

  async startClicked() {
    // Check if this is a Post-Trip and it has inspections to be done - handled differently than Pre-Trip, since
    // that's automatically started
    const now = new Date();

    const alertFailedInspectionOptions = {
      cssClass: 'alert-wrapperz',
      header: this.translateService.translate('TITLE.inspFailed'),
      message: this.translateService.translate('LABEL.criticalInspFailed'),
      buttons: [{
        text: this.translateService.translate('ACTION.ok').toUpperCase(),
        cssClass: 'primary',
      }],
    };

    const onInspectionDismiss = (success: boolean, inspectionItems: InspectionItem[]) => new Promise(async resolve => {
      this.nonServicePeriod.hasBeenStarted = true;

      await this.routeProvider.startNonServicePeriod(this.nonServicePeriod.nonServicePeriodType, now);
      await this.routeProvider.finishNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
      await this.dispatcherService.addInspectionReport(inspectionItems, this.nonServicePeriod.nonServicePeriodType);

      if (success) { await this.modalController.dismiss(); resolve(true); return; }

      // inspection failed
      await this.alertService.presentAlert(alertFailedInspectionOptions);
      await this.dispatcherService.logout();
      const _title = this.translateService.translate('ACTION.login');
      this.title.setTitle(_title);
      await this.router.navigate(['/login']);
      resolve(false);
    });

    if (
      //  is a post trip and requires inspection
      this.nonServicePeriod.nonServicePeriodType === NonServicePeriodTypeEnum.PostTrip
    ) {
      if (this.data.route.postTrip.inspectionItems?.length) {
        const inspectionModal = await this.modalController.create({
          cssClass: 'parascope-full-modal',
          backdropDismiss: false,
          component: InspectionPage,
          componentProps: {
            nonServicePeriod: this.nonServicePeriod,
            onComplete: async (success: boolean, inspectionItems: InspectionItem[]) => await onInspectionDismiss(success, inspectionItems),
          },
        });
        await inspectionModal.present();
      } else if (this.nonServicePeriod.hasBeenStarted) {
        await this.routeProvider.finishNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
      } else {
        await this.routeProvider.startNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
      }
    } else if (this.nonServicePeriod.nonServicePeriodType === NonServicePeriodTypeEnum.Break) {
      //  is a break
      this.nonServicePeriod.hasBeenStarted = true;
      await this.routeProvider.startNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
    }
  };

  async finishClicked() {
    await this.routeProvider.finishNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
  }
}
